<template>
  <!-- 暂无数据 -->
  <div class="no-index">
      <img src="../../assets/img/blank.png" >
      <div>No data</div>
  </div>
</template>

<script>
  export default {
    name:'noData'
  }
</script>

<style scoped lang="scss">
  .no-index{
    border: 0;
    width: 100%;
    //height: 780px;
    //background-color: #FFFFFF;
    text-align: center;
    padding-bottom: 50px;
    img{
      width: 300px;
      height: 250px;
      margin: 50px auto 20px auto;
    }
    div{
      color: #666666;
      font-size: 24px;
    }
  }
</style>
