import request from '@/utils/request'

const url = 'api/withdraw/'

//获取阿里云权限 get
export const getWithdrawConfig = (data) => request(url+'config','get',data,false)
export const setWithdraw = (data) => request(url+'withdraw','get',data,false)
export const getWithdrawList = (data) => request(url+'list','get',data,false)
export const getWithdrawItem = (data) => request(url+'item','get',data,false)

