<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 30px 120px;">
      <div class="nav-flex">
        <div :class="[activeNav==1?'nav-active':'']" @click="btnNav(1)">{{ $t('order.bbdd') }}</div>
        <div :class="[activeNav==2?'nav-active':'']" @click="btnNav(2)" style="margin-right: 0;">{{ $t('order.qhdd') }}</div>
      </div>
      <div class="main">
        <div class="main-head" v-if="activeNav == 1">
          <div class="quotation-box">
            <div class="quotation-box-head">
              <div style="display: flex;align-items: center;">
                <div class="quotation-box-nav" :class="[activeIndex==1?'quotation-active':'']" @click="btn(1)">
                  <div class="quotation-box-tit">{{ $t('order.dqwt') }}</div>
                </div>
                <div class="quotation-box-nav" :class="[activeIndex==2?'quotation-active':'']" @click="btn(2)">
                  <div class="quotation-box-tit">{{ $t('order.lswt') }}</div>
                </div>
              </div>
            </div>
            <div v-if="activeIndex == 1">
              <div class="quotation-list">
                <div class="quotation-list-tit">
                  <div style="text-align: left;">{{ $t('order.jyd') }}</div>
                  <div>{{ $t('order.cjsj') }}</div>
                  <div style="text-align: right;">{{ $t('order.fx') }}</div>
                  <div style="text-align: right;">{{ $t('order.wtjg') }}</div>
                  <div style="text-align: right;">{{ $t('order.ddsl') }}</div>
                  <div style="text-align: right;">{{ $t('order.ycjsl') }}</div>
                  <div style="text-align: right;">{{ $t('order.wtje') }}</div>
                  <div style="text-align: right;">{{ $t('order.cz') }}</div>
                </div>
              </div>
              <div class="quotation-list" v-for="(item,index) in getData" :key="index" >
                <div class="quotation-list-tit">
                  <div style="text-align: left;"><span style="color: #FFF;">{{item.coin_name}}</span> /USDT</div>
                  <div>{{item.created_at}}</div>
                  <div :class="item.type === 1?'green':'red'"
                    style="text-align: right;">
                    {{ item.type === 1?$t('transaction.mr'):$t('transaction.mc')}}
                  </div>
                  <div style="text-align: right;">{{item.bid_price.toFixed(item.bid_price>1000?2:6)}} USDT</div>
                  <div style="text-align: right;">{{item.bid_num.toFixed(item.bid_num>1000?2:6)}} {{item.coin_name}}</div>
                  <div style="text-align: right;">{{item.income_num.toFixed(item.income_num>1000?2:6)}} {{item.coin_name}}</div>
                  <div style="text-align: right;">{{setTransactionAmount(item.income_num,item.bid_num,item.bid_price)}} USDT</div>
                  <div class="revoke-felx" @click="cancel(item)">
                    <span>{{ $t('order.cd') }}</span>
                    <img class="revoke-img" src="../../assets/market/jtl.png" alt="">
                  </div>
                </div>
              </div>
              <no-data v-if="getData.length<=0"></no-data>
              <div class="page" >
                <pagination @current-change="handleCurrentChange"  :total="total"></pagination>
              </div>
            </div>
            <div v-if="activeIndex == 2">
              <div class="quotation-list">
                <div class="quotation-list-tit">
                  <div style="text-align: left;">{{ $t('order.jyd') }}</div>
                  <div>{{ $t('order.cjsj') }}</div>
                  <div style="text-align: right;">{{ $t('order.fx') }}</div>
                  <div style="text-align: right;">{{ $t('order.wtjg') }}</div>
                  <div style="text-align: right;">{{ $t('order.ddsl') }}</div>
                  <div style="text-align: right;">{{ $t('order.ycjsl') }}</div>
                  <div style="text-align: right;">{{ $t('order.wtje') }}</div>
                  <div style="text-align: right;">{{ $t('order.zt') }}</div>
                </div>
              </div>
              <div class="quotation-list" v-for="(item,index) in getData" :key="index" >
                <div class="quotation-list-tit">
                  <div style="text-align: left;"><span style="color: #FFF;">{{item.coin_name}}</span> /USDT</div>
                  <div>{{item.created_at}}</div>
                  <div :class="item.type === 1?'green':'red'"
                    style="text-align: right;">
                    {{ item.type === 1?$t('transaction.mr'):$t('transaction.mc')}}
                  </div>
                  <div style="text-align: right;">{{item.bid_price.toFixed(item.bid_price>1000?2:6)}} USDT</div>
                  <div style="text-align: right;">{{item.bid_num.toFixed(item.bid_num>1000?2:6)}} {{item.coin_name}}</div>
                  <div style="text-align: right;">{{item.income_num.toFixed(item.income_num>1000?2:6)}} {{item.coin_name}}</div>
                  <div style="text-align: right;">{{setTransactionAmount(item.income_num,item.bid_num,item.bid_price)}} USDT</div>
                  <div style="text-align: right;">{{item.status_str}}</div>
                </div>
              </div>
              <no-data v-if="getData.length<=0"></no-data>
              <div class="page">
                <pagination @current-change="handleCurrentChange"  :total="total"></pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="main-head" v-if="activeNav == 2">
          <div class="quotation-box">
            <div class="quotation-box-head">
              <div style="display: flex;align-items: center;">
                <div class="quotation-box-nav" :class="[activeIndex2==1?'quotation-active':'']" @click="btn3(1)">
                  <div class="quotation-box-tit">{{ $t('order.dqwt') }}</div>
                </div>
                <div class="quotation-box-nav" :class="[activeIndex2==2?'quotation-active':'']" @click="btn3(2)">
                  <div class="quotation-box-tit">{{ $t('order.lswt') }}</div>
                </div>
              </div>
            </div>
            <div v-if="activeIndex2 == 1">
              <div class="quotation-list">
                <div class="quotation-list-tit">
                  <div style="text-align: left;">{{ $t('order.jyd') }}</div>
                  <div style="text-align: center;width: 6.5%;">{{ $t('order.fx') }}</div>
                  <div style="text-align: center;">{{ $t('order.ddsl') }}</div>
                  <div style="width:18.5%">{{ $t('order.kssj') }}</div>
                  <div style="text-align: center;">{{ $t('order.ksjg') }}</div>
                  <div style="text-align: right;">{{ $t('order.jssj') }}</div>
                  <div style="text-align: right;">{{ $t('order.yjyl') }}</div>
                  <div style="text-align: right;">{{ $t('order.kpdjs') }}</div>
                </div>
              </div>
              <div class="quotation-list" v-for="(item,index) in getData" :key="index">
                <div class="quotation-list-tit">
                  <div style="text-align: left;"><span style="color: #FFF;">{{item.coin_name}}</span> /USDT</div>
                  <div style="text-align: center;width: 6.5%;" class="green" :class="{'red':item.type!==1}">{{item.type===1? $t('order.mz'):$t('order.md') }}</div>
                  <div style="text-align: center;">{{item.money.toFixed(item.money>1000?2:6)}} USDT</div>
                  <div style="width:18.5%">{{item.created_at}}</div>
                  <div style="text-align: center;">{{item.coin_price.toFixed(item.coin_price>1000?2:6)}}</div>
                  <div style="text-align: right;">{{item.end_at}}</div>
                  <div style="text-align: right;">{{item.profit}}%</div>
                  <div style="text-align: right;color: #FFC300;">{{item.end_num}} s</div>
                </div>
              </div>
              <no-data v-if="getData.length<=0"></no-data>
              <div class="page">
                <pagination @current-change="handleCurrentChange"  :total="total"></pagination>
              </div>
            </div>
            <div v-if="activeIndex2 == 2">
                <div class="quotation-list">
                    <div class="quotation-list-tit">
                      <div style="text-align: left;">{{ $t('order.jyd') }}</div>
                      <div style="text-align: center;width: 6.5%;">{{ $t('order.fx') }}</div>
                      <div style="text-align: center;">{{ $t('order.ddsl') }}</div>
                      <div style="width:18.5%">{{ $t('order.kssj') }}</div>
                      <div style="text-align: center;">{{ $t('order.ksjg') }}</div>
                      <div style="text-align: right;">{{ $t('order.jssj') }}</div>
                      <div style="text-align: right;">{{ $t('order.jsjg') }}</div>
                      <div style="text-align: right;">{{ $t('order.bdyl') }}</div>
                      <div style="text-align: right;">{{ $t('order.zt') }}</div>
                    </div>
                  </div>
                  <div class="quotation-list" v-for="(item,index) in getData" :key="index" >
                    <div class="quotation-list-tit">
                      <div style="text-align: left;"><span style="color: #FFF;">{{item.coin_name}}</span> /USDT</div>
                      <div style="text-align: center;width: 6.5%;" class="green" :class="{'red':item.type!==1}">{{item.type===1? $t('order.mz'):$t('order.md') }}</div>
                      <div style="text-align: center;">{{item.money.toFixed(item.money>1000?2:6)}} USDT</div>
                      <div style="width:18.5%">{{item.created_at}}</div>
                      <div style="text-align: center;">{{item.coin_price.toFixed(item.coin_price>1000?2:6)}}</div>
                      <div style="text-align: right;">{{item.end_at}}</div>
                      <div style="text-align: right;">{{item.end_price.toFixed(item.end_price>1000?2:6)}}</div>
                      <div style="text-align: right;" class="green">+{{item.profit_money.toFixed(item.profit_money>1000?2:6)}} USDT</div>
                      <div style="text-align: right;">{{item.status_str}}</div>
                    </div>
                  </div>
              <no-data v-if="getData.length<=0"></no-data>
                  <div class="page">
                    <pagination @current-change="handleCurrentChange"  :total="total"></pagination>
                  </div>
            </div>


          </div>

        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
  import heads from '../../components/header.vue'
  import footerb from '../../components/footer.vue'
  import {getWithdrawList} from "@/api/withdraw";
  import {getChargeList} from "@/api/money";
  import Pagination from "@/components/pagination/pagination";
  import {getFutureList, getFutureOrderList} from "@/api/future";
  import {getDealList,cancelDeal} from "@/api/deal";
  import NoData from "@/components/noData/noData";
  export default {
    data() {
      return {
        activeNav: 1,
        activeIndex2: 1,
        activeIndex: 1,
        page: 1,
        status:1,
        total: null,
        getData: [],
        isAjax: false,
        timeServe:null
      }
    },
    components: {
      NoData,
      Pagination,
      footerb,
      heads
    },
    mounted() {
      this.startList()
      if(this.timeServe){
        clearInterval(this.timeServe)
      }
      this.timeServe= setInterval(()=>{
        if(this.activeIndex2===1 && this.activeNav===2){
          this.getData.forEach((item,key)=>{
            if(item.end_num>0){
              --item.end_num
            }
          })
        }

      },1000)
    },
    methods: {
      setTransactionAmount(income_num,bid_num,bid_price){
        let num = 0
        num = income_num/bid_num * bid_price
        if(num >= 1000){
          return num.toFixed(2)
        }else{
          return num.toFixed(6)
        }
      },
      btnNav(id) {
        if(this.activeNav !== id){
          this.activeNav = id
          this.status=1
          this.activeIndex = 1
          this.activeIndex2 = 1
          this.startList()
        }
      },
      btn(id) {
        if(this.activeIndex !== id){
          this.activeIndex = id
          if(this.activeIndex===1){
            this.status=1
          }else {
            this.status='2,3'
          }
          this.startList()
        }

      },
      btn3(id) {
        if(this.activeIndex2 !== id){
          this.activeIndex2 = id
          if(this.activeIndex2===1){
            this.status=1
          }else {
            this.status='2,3'
          }
          this.startList()
        }
      },
      startList() {
        this.page = 1
        this.total = null
        this.getData = []
        this.isAjax = false
        this.getList()
      },
      handleCurrentChange(val) {
        console.log('val:' + val)
        this.page = val
        this.getList()
      },
      getList() {
        if (this.total !== null && this.total <= this.getData.length) {
          return false
        }
        if (this.isAjax) {
          return false
        }
        this.isAjax = true
        if (this.activeNav === 1) {
          this.getDealList()
        } else {
          this.getFutureOrderList()
        }
      },
      getFutureOrderList() {
        getFutureOrderList({'page': this.page,'status':this.status}).then((res) => {
          if (res.code === 1) {
            this.total = res.data.total
            this.getData = res.data.items
          }
          this.isAjax = false
        })
      },
      getDealList() {
        getDealList({'page': this.page,'status':this.status}).then((res) => {
          if (res.code === 1) {
            this.total = res.data.total
            this.getData = res.data.items
          }
          this.isAjax = false
        })
      },
      cancel(item){
        if(this.isAJAX){
          return
        }
        this.isAJAX = true
        cancelDeal({dealId:item.id}).then((res)=>{
          if(res.code === 1){
            this.isAJAX = false
            this.$message({
              message: this.$t('common_msg.success'),
              type: 'success'
            });
            this.page = 1
            this.getDealList()
          }else{
            this.isAJAX = false
          }
        })
      },
    },

  };

</script>
<style scoped>
  html {
    background: #171e28;
  }
  .green {
    color: #43CF7C;
  }
  .red {
    color: #FF6B65;
  }
  .main {
    width: 100%;
    height: auto;
    background: #1F282F;
    padding: 30px;
    box-sizing: border-box;
  }

  .main-head {
    width: 100%;
    height: auto;
    background: #171E28;
    margin-bottom: 20px;
    color: #FFFFFF;
  }

  .nav-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;


  }

  .nav-flex div {
    margin-right: 40px;
    padding-bottom: 6px;
  }

  .nav-active {
    border-bottom: 1px solid #FFC300;
    color: #FFC300;
  }

  .quotation-box {
    width: 100%;
    height: auto;
    /* background: #29343D; */
    padding: 28px 0 16px 0;
    margin-bottom: 30px;
  }

  .quotation-box-img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .quotation-box-tit {
    /* margin-bottom: 16px; */
    /* color: #FFFFFF; */
  }

  .quotation-box-nav {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 32px;
  }

  .quotation-active {
    border-bottom: 1px solid #FFC300;
    color: #FFC300 !important;
  }

  .quotation-box-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #1F282F;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0 60px;
    box-sizing: border-box;
  }

  .quotation-list {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #1F282F;
    padding-bottom: 10px;
    margin-bottom: 16px;
  }

  .quotation-list-tit {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 60px;
    text-align: center;
  }

  .quotation-list-tit div {
    width: 12.5%;
    font-size: 12px;
  }

  .quotation-list-tit3 div {
    width: 14%;
    font-size: 12px;
  }

  .green{
    color: #43CF7C;
  }


  .red {
    color: #FF6B65;
  }
  .revoke-felx {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    text-decoration-line: underline;
    color: rgba(255, 107, 101, 1);
    cursor: pointer;
  }

  .revoke-img {
    width: 16px;
    height: 16px;
  }

  .page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

</style>
