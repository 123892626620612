<template>
  <div>
    <el-pagination class="selectPage" small background prev-text="←" next-text="→" hide-on-single-page
                   @current-change="handleCurrentChange"  layout=" prev, pager, next"
                   :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "pagination",
  components: {},
  props: {
    'total':{
      default:0
    }
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {

  },
  methods: {
    handleCurrentChange(val){
      this.$emit('current-change',val)
    }
  }
}
</script>

<style scoped lang="scss">
  .selectPage {
    margin: 20px 40px 0 0;
    text-align: right;
    ::v-deep .btn-prev{
      color: #fff;
      background:none;
    }
    ::v-deep .el-pager{
      color: #fff;
      background: none;
    }
    ::v-deep .el-pager li{
      background: none;
    }
    ::v-deep .btn-next{
      color: #fff;
      background:none;
    }
  }
::v-deep {
  .el-pagination.is-background .btn-prev {
    background-color: #171E28;
  }

  .el-pagination.is-background .btn-next {
    background-color: #171E28;
  }

  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #171E28; // 进行修改未选中背景和字体
    // color: #fff;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #171E28 !important; // 进行修改选中项背景和字体
    color: #fff;
  }
}
</style>
