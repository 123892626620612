import request from '@/utils/request'

const url = 'api/money/'

//获取阿里云权限 get
export const getCoinList = (data) => request(url+'coin-list','get',data,true)
export const getCoinItem = (data) => request(url+'coin-item','get',data,false) //获取B种详情
export const getChargeList = (data) => request(url+'charge-list','get',data,false)
export const getChargeItem = (data) => request(url+'charge-item','get',data,false)
export const getWaterList = (data) => request(url+'water-list','get',data,false)
export const getWaterType = (data) => request(url+'water-type','get',data,false)

